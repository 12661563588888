
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import HomeLayout from '@/app/layouts/HomeLayout.vue';
import NextProducts from '@/components/NextProducts.vue';
import SearchResults from '@/components/SearchResults.vue';
import IProducts from '@/module/main/dto/IProducts';
import RouteName from '@/app/router/NameEnum';
import { getCulture } from "@/app/utils/EnvConfig";

@Component({
  components: {
    HomeLayout,
    NextProducts,
    SearchResults,
  },
})
export default class Instructions extends Vue {
  private routeName = RouteName;

  private searching = false;
  private isLoading = true;
  private searchResults: IProducts | null = null;
  private searchQuery = '';
  private searchNotSuccessful = false;

  private displayMore = false;
  private windowWidth = window.innerWidth;

  private collectSearchResults(searching: boolean, data: IProducts, query: string): void {
    this.searchQuery = query;
    this.isLoading = true;
    this.searching = searching;
    if (data?.data.length > 0) {
      this.searchResults = data;
      this.searchNotSuccessful = false;
      this.isLoading = false;
    } else {
      this.searchNotSuccessful = true;
      this.isLoading = false;
    }
  }

  private collectCategoryData(): void {
    this.$forceUpdate();
  }

  private receiveLoading(loadingLayout: boolean) {
    this.isLoading = loadingLayout;
  }

  private get isSk(): boolean {
    return getCulture() === 'sk-SK';
  }

  private toogleCollapseIcon(e: any) {
    if (e.target.classList.contains('collapsed')) {
      e.target.getElementsByTagName('img')[0].className = 'rotate-icon-back';
    } else {
      e.target.getElementsByTagName('img')[0].className = 'rotate-icon';
    }
  }

  created() {
    this.isLoading = false;
    this.displayMore = false;
  }

  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
